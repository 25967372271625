import store from '@/store';
import _ from 'lodash';

export class LookupService {
  public static async searchByName(name: string) {
    const config = _.get(store, 'state.app.config.companyComplete', {});
    if (config.src && config.key) {
      const query = '?name=' + encodeURIComponent(name) + '&maxResults=10&guid=' + config.key;
      const response = await fetch(config.src + query, {
        headers: {Accept: 'text/javascript; charset=utf-8'}
      }).catch((e) => {
        console.error(e);
      });
      if (response && response.ok) {
        const text = await response.text();
        if (text) {
          const obj = text.replace(/^callback\(/, '').replace(/\)\s*$/, '');
          const json = JSON.parse(obj);
          if (!json.Message) {
            return json.Names;
          }
        }
      }
    }
  }
}

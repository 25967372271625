









import {Component, Vue, Watch} from 'vue-property-decorator';
import {State, Action, Getter} from 'vuex-class';
import _ from 'lodash';
import PortalStepper from '@/views/auth/Portal/PortalStepper.vue';
import Footer from '@/components/layouts/Footer.vue';
import PolicyService from '@/services/PolicyService';
import QuoteService from '@/services/QuoteService';
import Util from '@/utils/Util';
import EventBus from '@/common/EventBus';
import {IBuildPayload, IRawProduct, IRawProducts} from '@/interfaces';
import {IPresetFactPayload, ISetPolicyStartDatePayload} from '@/interfaces/IQuotation';
import {APIResource} from '@/utils/APIResources';
import config from '@/config';

@Component({
  name: 'Portal',
  components: {
    PortalStepper,
    Footer
  },
})
export default class Portal extends Vue {
  @State private app: any;
  @State private proposal: any;
  @State private cms: any;
  @State private quotation: any;
  @State private products: any;
  @Action('products/list') private loadShiftProducts: any;
  @Action('products/loadProduct') private loadProduct: any;
  @Action('proposal/init') private initProposal: any;
  @Action('app/setFooterVisibility') private setFooterVisibility: any;
  @Action('app/setPolicyIds') private setPolicyIds: any;
  @Action('app/setValue') private setValue: any;
  @Action('proposal/addProduct') private addProductToProposal: any;
  @Action('proposal/setProductSession') private setProductSession: any; // not in used
  @Action('quotation/build') private buildQuotation!: (payload: IBuildPayload) => void;
  @Action('quotation/restoreFromPolicyStatus') private restoreFromPolicyStatus!: (restoreProductIDs: string[]) => void;
  @Action('quotation/restoreFromSavedProposal') private restoreFromSavedProposal: any;
  @Action('app/initPaymentProviders') private initPaymentProviders: any;
  @Action('quotation/reset') private resetQuotation: any;
  @Action('app/getQuotes') private getQuotes: any;
  @Action('app/setProducts') private setProducts: any;
  @Action('app/setActiveStep') private setActiveStep: any;
  @Action('quotation/setPolicyDate') private setPolicyDate!: (payload: ISetPolicyStartDatePayload) => void;
  @Action('proposal/setBasicInfo') private setBasicInfo: any;
  @Action('proposal/updateBasicInfo') private updateBasicInfo: any;
  @Action('app/setContentReady') private setContentReady!: (payload: boolean) => void;
  @Getter('products/getProductByCode') private getProductByCode!: (code: string) => IRawProduct;
  @Getter('app/getRenewalResponse') private getRenewalResponse: any;
  @Getter('products/getProductsSortedLatest') private getAllProducts!: () => IRawProducts;
  @Getter('quotation/getProducts') private getProducts: any;
  @Getter('quotation/hasPastCoverageStartDate') private hasPastCoverageStartDate: any;
  @Getter('cms/loadBasicInfoForm') private loadBasicInfoForm: any;
  @Getter('auth/getGuestParty') private getGuestParty: any;

  private initLoadingMessages = {
    NEW: 'newBusiness',
    RENEWAL: 'renewals',
    CONTINUATION: 'continuation'
  };

  private hasPopCoverageExpireDialog = false;

  private onVisibleChanged(this: any, isVisible: any, entry: any) {
    this.setFooterVisibility(isVisible);
  }

  private async created() {
    let message = this.initLoadingMessages.NEW;
    let isGuest = false;
    if (this.app.isRenewal) {
      message = this.initLoadingMessages.RENEWAL;
    } else if (this.app.isContinuation) {
      message = this.initLoadingMessages.CONTINUATION;
    } else {
      isGuest = true;
    }
    // when launch portal page directly, the guest party may not be set.
    if (!this.getGuestParty()) {
      APIResource.setUser(isGuest);
    }
    EventBus.$emit('open-loading-dialog', this.$t(`notifications.init.${message}`));
    this.checkContentReady();
    EventBus.$on('went-idle', () => {
      this.$dialog.open({
        persistent: true,
        icon: this.getIcon,
        text: this.getGoneIdleMessage,
        buttons: [
          {
            type: 'main',
            text: this.$t('button.ok'),
            onClick: this.backToLanding
          }
        ]
      });
    });

    EventBus.$on('want-back', (skipConfirm) => {
      if (skipConfirm === 'true') {
        this.backToLanding();
        return;
      }
      this.$dialog.open({
        icon: this.getIcon,
        text: this.getBackButtonMessage,
        buttons: [
          {
            text: this.$t('button.cancel')
          },
          {
            type: 'main',
            text: this.$t('button.ok'),
            onClick: this.backToLanding
          }
        ]
      });
    });

    EventBus.$on('redirect-error', () => {
      const afterUW = this.$route.name === 'review';
      if (afterUW) {
        if (this.app.activeStep === 1) {
          this.$dialog.open({ type: 'redirection-issue-summary', info: 'Review (Summary): redirection to confirmation failed' });
        } else if (this.app.activeStep === 2) {
          this.$dialog.open({ type: 'redirection-issue-payment', info: 'Review (Payment): redirection to confirmation failed' });
        } else {
          this.$dialog.open({type: 'redirection-issue', info: 'Review: redirection failed'});
        }
      } else {
        if (this.app.activeStep === 5) {
          this.$dialog.open({ type: 'redirection-issue-summary', info: 'Portal (Summary): redirection to confirmation failed' });
        } else if (this.app.activeStep === 6) {
          this.$dialog.open({ type: 'redirection-issue-payment', info: 'Portal (Payment): redirection to confirmation failed' });
        } else {
          this.$dialog.open({type: 'redirection-issue', info: 'Portal: redirection failed'});
        }
      }
    });

    EventBus.$on('product:added', this.cloneInfo);
    Util.gaLogPageView(this, `/portal+${sessionStorage.subSegment ? sessionStorage.subSegment : sessionStorage.targetSegment}`);

    EventBus.$on('policy:reload', this.loadPolicy);
  }

  private async checkContentReady() {
    if (!this.getAllProducts().length) {
      const response = await this.loadShiftProducts();
      if (response === undefined || response.toString() !== '') {
        return setTimeout(() => { this.checkContentReady(); }, 1000);
      }
      return this.$dialog.open({type: 'technical-issue', info: 'Shift: no products'});
    }
    if (this.app.isSavedProposal) {
      await this.loadSavedProposal();
      await this.initGoogleMaps();
    } else {
      if (this.app.isRenewal) {
        const productIds = _.values(this.getRenewalResponse('renewalIds'));
        for (const pid of productIds) {
          if (!pid) {
            continue;
          }
          await this.loadProduct(pid);
        }
      } else if (this.app.isContinuation) {
        await this.loadPolicy();
      }
      await this.initData();
    }
    await this.initPaymentProviders();
    setTimeout(() => {
      this.setContentReady(true);
      EventBus.$emit('close-loading-dialog');
    }, 300);
  }

  private async initData() {

    await this.initGoogleMaps();

    // reset quotation when page started with landing
    const startLoc = _.get(this.$router, 'history._startLocation', '');
    const shouldResetQuotation: boolean = startLoc.includes('landing')
      || startLoc.includes('renewal')
      || startLoc.includes('callback')
      || _.get(this.$router, 'referer.path', '').includes('landing');

    // getSelectedProducts
    const products: any = this.$route.query.products;

    // Build Quotation
    const presetFacts: IPresetFactPayload[] = [];
    const queryFactId: string = (this.$route.query.fi as string);
    const queryFactVal: string = (this.$route.query.fd as string);
    if (queryFactId && queryFactVal) {
      const factIdData = queryFactId.split('_');
      const productCode = factIdData[0];
      const factID = factIdData[1].replace('-', ':');
      presetFacts.push({productCode, factID, factValue: queryFactVal});
    }
    this.buildQuotation({resetQuotationForCache: shouldResetQuotation, products, presetFacts});
  }

  private async initGoogleMaps() {
    const ac = _.get(this.app, 'config.addressComplete');
    Util.initGoogleMaps(ac);
  }

  private cloneInfo(productId) {
    this.$global.enterAnimation();
    _.map(this.proposal.basicInfo, (field, key) => {
      _.forEach(field.shiftFactIds, (shiftFactId) => {
        if (field.value !== undefined) {
          EventBus.$emit('update:fact', productId, shiftFactId, field.value);
        }
      });
    });
  }

  private async loadSavedProposal() {
    const proposalResponse = await QuoteService.getQuotes(this.app.proposalId).then((response) => {
      const quotes = response.data.data.quotes;
      // Apply for all product's quotations, When user launches the Saved proposal, the previous selection of "Read" cleared off.
      quotes.forEach((quote) => {
        const productConfig = JSON.parse(quote.productConfig);
        if (productConfig.factValues['further.information.statement']) {
          delete productConfig.factValues['further.information.statement'];
          quote.productConfig = JSON.stringify(productConfig);
        }
      });
      return response;
    }).catch((error: any) => {
      console.error('Empty proposal', error);
      EventBus.$emit('close-loading-dialog');
      this.$router.push({path: '/error'});
      return;
    });
    const proposal = _.get(proposalResponse, 'data.data', []);
    this.setValue({code: 'savedProposal', value: proposal});

    // rebuild basic info
    try {
      const factValues = await JSON.parse(proposal.quotes[0].productConfig).factValues;

      const basicInfoForm = await this.loadBasicInfoForm(this.app.targetSegment);
      await this.setBasicInfo(basicInfoForm);
      for (const factKey of Object.keys(factValues)) {
        await this.updateBasicInfo({factID: factKey, value: factValues[factKey]});
      }
    } catch (error) {
      console.error('missing quotes' + error);
      this.$router.push({path: '/error'});
      EventBus.$emit('close-loading-dialog');
    }

    // proposal has expired
    if (proposal.expiry && (new Date(proposal.expiry) < new Date())) {
      this.$dialog.open({type: 'proposal-expired', info: 'proposal-expired'});
      this.$router.replace({name: 'landing', params: {targetSegment: this.app.targetSegment}});
    }

    // restore quotes cache
    const productIds = proposal.quotes.map((quote) => quote.productId);
    _.forEach(productIds, async (productId) => await this.loadProduct(productId));
    await this.restoreFromSavedProposal(proposal.quotes);
  }

  private async loadPolicy(rerun: boolean = false) {
    const pStatus = await PolicyService.getPolicyByBundle(this.app.bundleId).catch((e: any) => {
      this.$router.push({path: '/error'});
    });
    const arr = _.get(pStatus, 'data.data', []);
    const entries = arr.filter((e) => e.status === 'PENDING_PAYMENT' || e.status === 'PENDING_PAYMENT_REVIEWED' || e.status === 'PENDING_PAYMENT_UNLAPSED' || e.status === 'LAPSING');
    this.setPolicyIds(entries.map((e) => e.id));
    let fail = false;
    const statuses = {};
    const codes: string[] = [];
    const productIds: string[] = [];
    let hasUpdate = false;
    for (const policyId of this.app.policyIds) {
      const status = await PolicyService.getPolicyStatus(policyId).catch((e: any) => {
        console.error(e.message || e);
        this.$router.push({path: '/error'});
      });
      if (status) {
        const pid = _.get(status, 'data.data.productId');
        const product = this.getAllProducts().find((p) => p.id === pid);
        if (!rerun) {
          await this.loadProduct(pid);
          hasUpdate = true;
          continue;
        }
        const code = _.get(product, 'code');
        if (code && pid) {
          productIds.push(pid);
          codes.push(code);
          statuses[code] = status;
        }
      } else {
        fail = true;
        break;
      }
    }
    if (hasUpdate) {
      return this.loadPolicy(true);
    }
    if (fail) {
      return this.$router.push({path: '/error'});
    }
    this.restoreFromPolicyStatus(entries);
  }

  private backToLanding() {
    EventBus.$emit('init-app', () => {
      this.setValue({code: 'dirty', value: false});
      this.$router.push({name: 'landing'});
      this.resetQuotation();
    });
  }

  get getGoneIdleMessage() {
    return this.$t('notifications.timeout');
  }

  get getBackButtonMessage() {
    return this.$t('notifications.backButton');
  }

  get getIcon() {
    return this.cms.theme.errorIcon;
  }

  @Watch('quotation.products', {deep: true})
  private onQuoteAdded(products: any) {
    // saved proposal link where product has a coverage start date in the past
    if (!this.app.isRenewal && this.app.isSavedProposal && products) {
      // coverage start date is in the past
      if (this.hasPastCoverageStartDate() && !this.hasPopCoverageExpireDialog) {
        this.hasPopCoverageExpireDialog = true;
        this.$dialog.open({type: 'past-coverage-start-date', info: 'past-coverage-start-date'});
        (this.$refs.portalStepper as any).stepHandler();
      }
    }
  }
}

import Util from '@/utils/Util';

export default class {
  public static getValidationRuleByFact(fact: any) {
    const FACT_NUMBER = 'NUMBER';
    const FACT_TEXT = 'TEXT';
    const FACT_EMAIL = 'EMAIL';
    const FACT_PHONE = 'PHONE_NUMBER';
    const FACT_UPLOAD = 'FILE_REFERENCE';

    const validators: string[] = [];
    if (Util.getDeclarationFacts().includes(fact.id)) {
      validators.push('declaration');
    }

    if (fact.mandatory === true) {
      validators.push('required');
    }

    if (fact.type === FACT_TEXT && fact.formatType === 'integer') {
      validators.push('text_integer');
    }

    if (fact.type === FACT_PHONE) {
      validators.push('phone');
    }

    if (fact.type === FACT_UPLOAD) {
      fact.formatOptions.forEach((option) => {
        if (option.key === 'type') {
          validators.push('file:' + option.value);
        }
      });
    }

    if (fact.type === FACT_NUMBER || (fact.type === FACT_TEXT && fact.formatType === 'integer')) {
      fact.formatOptions.forEach((option) => {
        if (option.key === 'minValue') {
          validators.push('min_value:' + option.value);
        }
        if (option.key === 'maxValue') {
          validators.push('max_value:' + option.value);
        }
      });
    }

    if (fact.type === FACT_EMAIL) {
      validators.push('email');
    }

    if ([Util.COINSURANCE_FACT_ID, Util.WARNINGS_FACT_ID, Util.ELIGIBILITY_FACT_ID].includes(fact.id)) {
      validators.push('must_agree');
    }

    return validators.join('|');
  }
}

import EventBus from '@/common/EventBus';
import {IMap} from '@/interfaces';
import {Product, App} from '@/models';
import store from '@/store';
import FactValidationService from '@/services/FactValidationService';
import {default as ProductEngineUtils, USE_POLICY} from '@/utils/ProductEngineUtils';
import {CMSContentService} from '@/services/CMSService';
import Util from '@/utils/Util';
import TechUtils from '@/utils/Util';
import utils from '@/views/auth/Portal/PortalStepperItems/Utils';
import _ from 'lodash';
import moment from 'moment';
import {diff} from 'deep-diff';

const COVERAGE_DETAILS_SECTIONS_ORDER = 'COVERAGE_DETAILS_SECTIONS_ORDER';
const OMITTED_COVERAGE_DETAILS_SECTIONS = 'OMITTED_COVERAGE_DETAILS_SECTIONS';

const OMITTED_VERIFICATION_FACTS = 'OMITTED_VERIFICATION_FACTS';

const SYNONYMS = 'SYNONYMS';
const AGREEMENT_FACTS = 'AGREEMENT_FACTS';
const CGL_DETAILS_SECTION = 'CGL_DETAILS_SECTION';

const INSURED_TYPES_COVERAGE = 'OMITTED_INSURED_TYPES_CONFIGURATION';

const NAMED_FACT_GROUPS = 'NAMED_FACT_GROUPS';
const NAMED_FACT_GROUPS_COVERAGE = 'NAMED_FACT_GROUPS.COVERAGE';
const MANUAL_FACT_GROUPS = 'MANUAL_FACT_GROUPS';
const MANUAL_FACT_GROUPS_COVERAGE = 'MANUAL_FACT_GROUPS.COVERAGE';
const SECTION_GROUPS = 'SECTION_GROUPS';
const RENDER_AS_TEXT_LAYOUT = 'RENDER_AS_TEXT_LAYOUT';

export const SECTION_GROUP_NAME_OVERWRITE = 'SECTION_GROUP_NAME_OVERWRITE';
export const MAIN_SECTION_NAME = 'applicant';
export const COPY_MAIN_DETAILS_SECTION = 'COPY_MAIN_DETAILS_SECTION';

export const SHIFT_UPDATED = 'SHIFT-UPDATED';
export const SHIFT_APPROVED = 'SHIFT-APPROVED';
export const SHIFT_REJECTED = 'SHIFT-REJECTED';

export const ADDRESS_FIELD_SUFFIXES = [
  '.address',
  '.address2',
  '.postalcode',
  '.city',
  '.province',
  '.state',
  '.suburb'
];

export const NAME_ADDRESS_FIELD_SUFFIXES = [
  '.firstname',
  '.lastname',
  ...ADDRESS_FIELD_SUFFIXES
];

class FactServiceInternal {
  public static FACT_TYPE_MAP: IMap = {
    GROUP: 'FactLabel',
    INFO: 'FactInfo',
    BOOLEAN: 'FactSelection',
    DATE: 'FactDate',
    LIST: 'FactList',
    TEXT: 'FactText',
    MULTILINE_TEXT: 'FactMultilineText',
    EMAIL: 'FactText',
    NUMBER: 'FactNumber',
    PHONE_NUMBER: 'FactPhoneNumber',
    ADDRESS_AUTO_COMPLETE: 'FactAddressAutoComplete',
    COMPANY_AUTO_COMPLETE: 'FactCompanyAutoComplete',
    CUSTOM_MULTI_SELECT: 'CustomMultiBoolean'
  };

  private static instance: FactServiceInternal;
  private policyFactSynonyms: any;
  private factSynonyms: any;
  private products: any;
  private productTrees: any;
  private sessions: any;
  private lastFactRequiredValues = {};
  private updateInProgress = false;

  private sectionGroups: any;

  private dirtyTrees: any;

  private policyResults: any;

  public static get Instance() {
    return this.instance || (this.instance = new this());
  }

  public assetGroupsFacts(asset) {
    const groups = utils.getFactGroups(
      asset.allFacts,
      this.namedFactGroups(),
      this.manualFactGroups()
    );
    const realGroups = groups.filter((g) => g.facts.some((f) => _.get(f, 'type.type') !== 'GROUP'));
    return realGroups;
  }

  // *
  public getOptions(productContent: any, factId: string) {
    return _.get(productContent, 'options', {})[factId.replace(/^PRODUCT:.*#/, '')];
  }

  // *
  public namedFactGroups() {
    return _.merge({..._.get(store.state.app.config.shiftMappings, NAMED_FACT_GROUPS, {})}, {..._.get(store.state.app.config.shiftMappings, NAMED_FACT_GROUPS_COVERAGE, {})});
  }

  // *
  public manualFactGroups() {
    return _.merge({..._.get(store.state.app.config.shiftMappings, MANUAL_FACT_GROUPS, {})}, {..._.get(store.state.app.config.shiftMappings, MANUAL_FACT_GROUPS_COVERAGE, {})});
  }

  // *
  public sectionGroupNameOverwrite(sectionName: string, isMixedProduct: boolean , productCode: string = ''): string {
    const configuredName: {productCode: string, groupName: string} = _.get(store.state.app.config.shiftMappings, `${SECTION_GROUP_NAME_OVERWRITE}[${sectionName}]`, {});
    return !isMixedProduct && productCode  ===  configuredName.productCode ? configuredName.groupName : sectionName;
  }

  // *
  public getFactType(factType: string) {
    const NON_CHARACTER_REGEX = /[^a-zA-Z_]/g;
    return FactServiceInternal.FACT_TYPE_MAP[factType.replace(NON_CHARACTER_REGEX, '').toUpperCase()];
  }

  // *
  public isNumericText(fact) {
    return fact.type === 'NUMBER' && _.get(store.state.app.config.shiftMappings, RENDER_AS_TEXT_LAYOUT, []).some((pattern) => fact.id.endsWith(pattern));
  }

  // *
  public hasCoverageInsuredType(insuredType) {
    return _.includes(_.get(store.state.app.config.shiftMappings, INSURED_TYPES_COVERAGE, []), insuredType.type);
  }

  // *
  public getConfigByType(fact: any) {
    if (_.has(_.get(store.state.app.config.shiftMappings, AGREEMENT_FACTS, {}), _.split(fact.id, '#').pop() || '')) {
      return 'FactAgree';
    }
    if (fact.formatType === 'checkbox') {
      return 'FactSelection';
    }
    if (fact.type === 'BOOLEAN') {
      return 'FactOption';
    }
    if (fact.type!.match(Util.COMPONENT_PHONE_TYPE_ID)) {
      return 'FactPhoneNumber';
    }
    const addressType = fact.id && String(fact.id).match(/\.address$/) && !_.isEmpty(store.state.app.config.addressComplete) && 'ADDRESS_AUTO_COMPLETE';
    const companyType = fact.id && !!_.find(_.get(store.state.app.config.shiftMappings, 'COMPANY_AUTO_COMPLETE', []), (exp) => String(fact.id).match(new RegExp(exp, 'i'))) && !_.isEmpty(store.state.app.config.companyComplete) && 'COMPANY_AUTO_COMPLETE';
    return this.getFactType(addressType || companyType || fact.type);
  }

  // *
  public getValidators(fact: any) {
    const validation = FactValidationService.getValidationRuleByFact(fact).split('|') || [];
    if (!store.state.app.isRenewal && fact.childOf === 'renewal') {
      validation.push('required');
      fact.mandatory = true;
    }
    return _.compact(validation).join('|');
  }
}

export const FactService = FactServiceInternal.Instance;
